<script setup lang="ts">
import { SharePreview } from '../components/SlipShare';
import { useSlipShareRoutePage } from '../submodules/slip-share/composables';

const {
  bookingCode,
  imageUrl,
  shareLink,
  amountSwitcherIsVisible,
  showAmountEnabled,
  isSharingPlacedBet,
  isPending,
  errorMessage,
  closeModal,
  canselShare,
  onShowAmountClick,
  handleLogShareSuccess,
  handleLogLinkCopied,
  setLogData,
} = useSlipShareRoutePage();
</script>

<template>
  <div v-auto-id="'SlipShareRoutePage'"
    :class="{
      [$style['share-page-wrapper']]: true,
      [$style['share-page-wrapper--centered']]: !!null && errorMessage,
    }"
    @vue:before-unmount="canselShare"
  >
    <SharePreview
      :is-loading="isPending"
      :booking-code="bookingCode"
      :image-url="imageUrl"
      :share-link="shareLink"
      :show-stake-amount-switcher="amountSwitcherIsVisible"
      :show-amount-enabled="showAmountEnabled"
      :is-sharing-placed-bet="isSharingPlacedBet"
      :error-message="errorMessage"
      @done="closeModal"
      @show-amount-click="onShowAmountClick"
      @share-success="handleLogShareSuccess"
      @copy-link="handleLogLinkCopied"
      @content-ready="setLogData"
    />
  </div>
</template>

<style module lang="scss">
@include for-layout using($isDesktop) {
  .share-page-wrapper {
    @if $isDesktop {
      padding: 0 28px 40px;
    } @else {
      padding: 0 16px 20px;
    }

    &--centered {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
