import type { ComputedRef, Ref } from 'vue';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import { useIntersectionObserver } from '@leon-hub/vue-utils';

import { getHistoryDetailsRoute } from '../../../utils';
import type { SlipListHistoryItemData, SlipListHistoryItemProps } from '../../SlipListItem/types';
import type { BetSlipMyBetsEmits, BetSlipMyBetsProps } from '../types';

interface UseBetSlipMyBets {
  isLoaderVisible: ComputedRef<boolean>;
  isEmpty: ComputedRef<boolean>;
  isFooterHidden: ComputedRef<boolean>;
  isFooterSticky: ComputedRef<boolean>;
  isIntersectionObserverAvailable: Ref<boolean>;
  loadMoreObserverTarget: Ref<HTMLElement | undefined>;
  formattedList: ComputedRef<Omit<SlipListHistoryItemProps, 'cashoutIcon'>[]>;

  emitCashout(item: SlipListHistoryItemData): void;

  emitLoadMore(): void;

  onAppear(): void;

  emitHistoryClick(): void;

  emitItemClick(id: number): void;
}

export default function useBetSlipMyBets(
  props: BetSlipMyBetsProps,
  emit: BetSlipMyBetsEmits,
): UseBetSlipMyBets {
  const router = useRouter();

  const isLoaderVisible = computed<boolean>(() => !props.isLoaded && !props.list?.length);

  const isIntersectionObserverAvailable = ref<boolean>(true);

  const isEmpty = computed<boolean>(() => !!(props.isLoaded && !props.placedBetsCount));

  const isFooterHidden = computed<boolean>(() => !!(!isIntersectionObserverAvailable.value && props.isLoadingMore));

  const isFooterSticky = computed<boolean>(() => isIntersectionObserverAvailable.value && !isEmpty.value);

  const loadMoreObserverTarget = ref<HTMLElement | undefined>();

  const emitCashout = (item: SlipListHistoryItemData): void => {
    emit('cash-out', { betId: item.id, betType: item.betType, item });
  };

  const emitLoadMore = (): void => {
    emit('load-more');
  };

  const emitHistoryClick = (): void => {
    emit('history-link-click');
  };

  const emitItemClick = (id: number): void => {
    emit('item-click', id);
  };

  const detectObserverAvailability = (): void => {
    // for fallback UI
    isIntersectionObserverAvailable.value = !!process.env.VUE_APP_RENDERING_CSR
    && typeof window.IntersectionObserver === 'function';
  };

  const onAppear = (): void => {
    detectObserverAvailability();
  };

  useIntersectionObserver(loadMoreObserverTarget, (isIntersecting) => {
    if (isIntersecting && !props.isLoadingMore && !isEmpty.value) {
      emitLoadMore();
    }
  }, { threshold: 1 });

  const formattedList = computed<Omit<SlipListHistoryItemProps, 'cashoutIcon'>[]>(() => {
    if (!props.list) {
      return [];
    }
    return props.list.map(
      (item) => ({
        ...item,
        betDetailsUrl: router.resolve(getHistoryDetailsRoute(`${item.id}`)).href,
      }),
    );
  });

  return {
    isLoaderVisible,
    isEmpty,
    isFooterHidden,
    isFooterSticky,
    isIntersectionObserverAvailable,
    loadMoreObserverTarget,
    formattedList,
    emitCashout,
    emitLoadMore,
    onAppear,
    emitHistoryClick,
    emitItemClick,
  };
}
